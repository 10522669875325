/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 */
;(function ( $, window, document, undefined ) {

   var pluginName = 'svAutoComplete',
      defaults = {
         cssClasses: {
            resultList: 'sv-autocomplete-search-result',
            resultItem: 'sv-autocomplete-result-item',
            activeResultItem: 'sv-autocomplete-result-item-active'
         },
         appendTo: 'body',
         queryParameterName: 'term',
         delay: 200,
         cacheTTL: 300000
      },
      keyCode = {
         UP: 38,
         DOWN: 40,
         RETURN: 13,
         ESCAPE: 27,
         TAB: 9
      };

   function escapeHTML(string) {
      return string.replace(/&/g,'&amp;').replace(/</g,'&lt;').replace(/>/g,'&gt;');
   }

   function Plugin (element, options) {
      this.element = element;
      this.$element = $(element);
      if (options.selectedValueContainerId) {
         this.$selectedValueContainer = $('#' + options.selectedValueContainerId);
      }

      this.options = $.extend( {}, defaults, options );
      this._defaults = defaults;
      this._name = pluginName;
      this.init();
   }

   Plugin.prototype = {
      init: function () {

         var that = this;

         this.menuId = this.$element.attr('data-aria-owns') || this.$element.attr('id') + 'listbox';

         this.$element
            .attr({
               'aria-owns': this.menuId,
               'autocomplete': 'off'
            })
            .on({
               keydown: $.proxy(this.handleKeydown, this),
               keypress: $.proxy(this.handleKeypress, this),
               mousedown: $.proxy(this.handleMousedown, this),
               blur: $.proxy(this.handleBlur, this),
               paste: $.proxy(this.handlePaste, this)
            });

         this.$menu = $('<ul/>')
            .addClass(this.getCssClass('resultList'))
            .attr({
               'id': this.menuId,
               'role': 'listbox',
               'aria-labelledby': this.$element.attr('id'),
               'aria-expanded': 'false'
            })
            // SV-32715 - Must append LI to pass DIGG test
            .append('<li>Inga förslag</li>')
            .appendTo(this.options.appendTo)
            .hide();

         this.$menu.on('click', 'li', function() {
            var args = Array.prototype.slice.call(arguments, 0);
            args.splice(0, 0, this);
            that.handleItemClicked.apply(that, args);
         })
         .on('mousedown', $.proxy(function() {
            this.cancelBlur = true;
            window.setTimeout($.proxy(function() {
               delete this.cancelBlur;
            }, this), 10);
         }, this));
         this.cache = {};
      },

      getCssClass: function(name) {
         return this.options.cssClasses[name];
      },

      handleKeydown: function (e) {
         var which = e.which;
         if (which === keyCode.UP && !e.shiftKey) {
            e.preventDefault();
            this.previous();
         } else if (which === keyCode.DOWN && !e.shiftKey) {
            e.preventDefault();
            this.next();
         } else if (which === keyCode.RETURN || which === keyCode.TAB) {
            this.selectActive();
         } else if (which === keyCode.ESCAPE) {
            this.close();
         } else {
            this.search();
         }
      },

      handleKeypress: function(e) {
         if (e.shiftKey) {
            return;
         }

         switch(e.which) {
            case keyCode.UP:
               e.preventDefault();
               this.previous();
               break;
            case keyCode.DOWN:
               e.preventDefault();
               this.next();
               break;
         }
      },

      handleBlur: function() {
         if (this.cancelBlur) {
            delete this.cancelBlur;
            return;
         }

         window.clearTimeout(this.searchTimeout);
         this.close();
      },

      handlePaste: function() {
         this.search();
      },

      previous: function() {
         if (typeof this.activeItem === 'undefined' || this.activeItem === 0) {
            this.activeItem = this.$menu.find('li').length - 1;
         } else {
            this.activeItem = this.activeItem - 1;
         }

         this.activateItem(this.activeItem);
      },

      next: function() {
         var items = this.$menu.find('li');
         if (typeof this.activeItem === 'undefined' || this.activeItem === (items.length - 1)) {
            this.activeItem = 0;
         } else {
            this.activeItem = this.activeItem + 1;
         }

         this.activateItem(this.activeItem);
      },

      activateItem: function(item) {

         var activeClass = this.getCssClass('activeResultItem'),
            $menuItems = this.$menu.find('li'),
            $newActive = $menuItems.eq(item);

         $menuItems
            .removeClass(activeClass)
            .attr('aria-selected', 'false');

         $newActive
            .addClass(activeClass)
            .attr('aria-selected', 'true');

         this.$element
            .attr('aria-activedescendant', $newActive.attr('id'))
            .val($newActive.text());

         if (this.$selectedValueContainer && this.$selectedValueContainer.length) {
            this.$selectedValueContainer
               .empty()
               .append($newActive.text());
         }
      },

      selectActive: function() {
         var $active = this.$menu.find('.' + this.getCssClass('activeResultItem'));
         if ($active.length) {
            this.$element
               .attr('aria-activedescendant', $active.attr('id'))
               .val($active.text());
         } else {
            this.$element.removeAttr('aria-activedescendant');
         }
      },

      handleItemClicked: function(item, event) {
         event.preventDefault();
         var $item = $(item);
         this.activateItem($item.index());
         this.$element.val($(item).text());
         this.$element.closest('form').trigger('submit');
      },

      close: function() {
         if (this.xhr) {
            this.xhr.abort();
         }
         this.$menu
         .attr('aria-expanded', false)
         .hide();
         this.activeItem = undefined;
         this.$element.removeAttr('aria-activedescendant');
      },

      search: function() {
         var data = {},
            source = this.options.source;

         if (this.searchTimeout) {
            window.clearTimeout(this.searchTimeout);
         }

         this.searchTimeout = window.setTimeout($.proxy(function() {
            var term  = this.$element.val(),
               cachedResult = this.cache[term];

            if (!term || term.length < this.options.minLength) {
               this.close();
               return;
            }

            if (cachedResult && cachedResult.timestamp > new Date().getTime() - this.options.cacheTTL) {
               this.handleResult(cachedResult.value);
               return;
            }

            data[this.options.queryParameterName] = this.$element.val();

            this.xhr = $.ajax({
               url: source,
               dataType: 'json',
               data: data
            })
            .done($.proxy(this.handleResult, this))
            .done($.proxy(function(result) {
               if (!result) { return; }

               this.cache[term] = {
                  value: result,
                  timestamp: new Date().getTime()
               };
            }, this))
            .fail($.proxy(function() {
               this.$menu
               .attr('aria-expanded', false)
               .hide();
               return;
            }, this));
         }, this), this.options.delay);
      },

      handleResult: function(result) {
         if (!result) { return; }

         this.activeItem = undefined;
         this.$element.removeAttr('aria-activedescendant');

         var items = $.map(result, $.proxy(this.renderItem, this));
         this.$menu
            .html(items.join(''))
            .show()
            .attr('aria-expanded', true)
            .position({
               my: 'left top',
               at: 'left bottom',
               of: this.$element
            });

         if (this.$selectedValueContainer && this.$selectedValueContainer.length) {
            var locale = $('html').attr('lang') || 'sv',
               suggestions = locale === 'sv' ? ' sökförslag' : ' suggestions';

            this.$selectedValueContainer.text(result.length + suggestions);
         }
      },

      renderItem: function(value, i) {
         return '<li class="' + this.getCssClass('resultItem') + '" ' +
            'role="option" aria-selected="false" ' +
            'id="' + this.menuId + 'listboxitem' + i + '">' +
            '<a href="#">' + escapeHTML(value) + '</a></li>';
      }
   };

   // A really lightweight plugin wrapper around the constructor,
   // preventing against multiple instantiations
   $.fn[pluginName] = function (options) {
      return this.each(function() {
         if (!$.data(this, 'plugin_' + pluginName)) {
            $.data(this, 'plugin_' + pluginName, new Plugin(this, options));
         }
      });
   };

})(window.$svjq, window, document);
